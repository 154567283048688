import { GameConstants } from "../../GameConstants";
import { GameVars } from "../../GameVars";
import { BackgroundMenu } from "./BackgroundMenu";
import { Button } from "../../utils/Utils";
import { GameManager } from "../../GameManager";

export class WinLayer extends Phaser.GameObjects.Container {

    constructor(scene: Phaser.Scene) {

        super(scene);

        this.visible = false;
        const bck = new Phaser.GameObjects.Image(this.scene, 0, 0, "gradient_bck");
        bck.alpha = .7;
        bck.setOrigin(0);
        bck.setScale(GameVars.gameWidth / 16, GameVars.gameHeight / 256);
        this.add(bck);

        const midContainer = new Phaser.GameObjects.Container(this.scene, GameVars.gameWidth / 2, GameVars.gameHeight / 2 + (GameVars.desktop ? 40 : 100 * GameVars.scaleY));
        midContainer.scaleY = GameVars.scaleY;
        this.add(midContainer);

        if (GameVars.desktop) {
            midContainer.setScale(.9);
        }

        const title = new Phaser.GameObjects.Image(this.scene, 0, -400, "texture_atlas_1", "txt_win");
        midContainer.add(title);

        const midBck = new BackgroundMenu(this.scene, 0, 0, 700);
        midContainer.add(midBck);

        const aura1 = new Phaser.GameObjects.Image(this.scene, 0, -100, "texture_atlas_1", "win_aura_color");
        aura1.setScale(2);
        midContainer.add(aura1);

        this.scene.tweens.add({
            targets: aura1,
            angle: 360,
            ease: Phaser.Math.Easing.Linear,
            duration: 8000,
            repeat: -1
        });

        const trophy = new Phaser.GameObjects.Image(this.scene, 0, -100, "texture_atlas_1", "win_01");
        midContainer.add(trophy);

        const buttonsBck = new Phaser.GameObjects.Graphics(this.scene);
        buttonsBck.setPosition(0, 250);
        buttonsBck.fillStyle(0xffffff);
        buttonsBck.fillRoundedRect(-230, -30, 460, 60, 30);
        midContainer.add(buttonsBck);

        const restartBtn = new Button(this.scene, -120, 160, "texture_atlas_1", "btn_refresh", "btn_refresh_hover");
        restartBtn.onDown(this.onRestartDown, this);
        midContainer.add(restartBtn);

        const restartText = new Phaser.GameObjects.Text(this.scene, -120, 250, "Restart", { fontFamily: "Baloo", fontSize: "45px", color: "#265C80" });
        restartText.setOrigin(.5);
        midContainer.add(restartText);

        const homeBtn = new Button(this.scene, 120, 160, "texture_atlas_1", "btn_home", "btn_home_hover");
        homeBtn.onDown(this.onHomeDown, this);
        midContainer.add(homeBtn);

        const homeText = new Phaser.GameObjects.Text(this.scene, 120, 250, "Home", { fontFamily: "Baloo", fontSize: "45px", color: "#265C80" });
        homeText.setOrigin(.5);
        midContainer.add(homeText);
    }

    public show(): void {
        GAMESNACKS.levelComplete(0);

        this.alpha = 0;
        this.visible = true;

        this.scene.tweens.add({
            targets: this,
            alpha: 1,
            ease: Phaser.Math.Easing.Cubic.Out,
            duration: 500
        });
    }

    private onRestartDown(): void {

        GameManager.enterBoardScene(false);
    }

    private onHomeDown(): void {

        GameManager.enterSplashScene();
    }
}
