import { TutorialContainer } from "./TutorialContainer";
import { Block } from "./Block";
import { GameConstants } from "../../../GameConstants";
import { GameVars } from "../../../GameVars";
import { Chain } from "./Chain";
import { BoardManager } from "../BoardManager";

export class BoardContainer extends Phaser.GameObjects.Container {

    public static OFF_X = - (5 / 2) * GameConstants.CELL_SIZE;
    public static OFF_Y = - 307;

    public static currentInstance: BoardContainer;

    public boardScale: number;

    private blocks: Block[];
    private chains: Chain[];
    private tutorialContainer: TutorialContainer;

    constructor(scene: Phaser.Scene) {
        
        super(scene);

        BoardContainer.currentInstance = this;

        this.x = GameVars.gameWidth / 2;

        if (GameVars.desktop) {
            this.setScale(.75);
            this.y = GameVars.gameHeight / 2 + 85;
        } else {

            if (GameVars.scaleY > 1) {
                this.boardScale = .8;
            } else {
                this.boardScale = 1.2;
            }

            this.setScale(this.boardScale, GameVars.scaleY * this.boardScale);
            this.y = GameVars.gameHeight / 2 + 100 * GameVars.scaleY;
        }

        const grid = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", "grid");
        this.add(grid);

        const backContainer = new Phaser.GameObjects.Container(this.scene);
        this.add(backContainer);

        this.blocks = [];
        this.chains = [];

        let count = 0;
        let nums = [];

        if (GameVars.onTutorial) {
            nums = [4, 2, 5, 2, 6, 3];
        } else {
            // rellenamos todo de numeros menores al maximo
            for (let i = 0; i < 12; i++) {
                nums.push(Math.floor(Math.random() * (GameVars.startFrom - 1)) + 1);
            }

            // comprobamos que no se junten entre si
            for (let i = 0; i < 6; i++) {

                while (nums[i] === nums[i + 6]) {
                    nums[i] = Math.floor(Math.random() * (GameVars.startFrom - 1)) + 1;
                }
            }

            // añadimos el num maximo en una posicion random
            nums[Math.floor(Math.random() * 12)] = GameVars.startFrom;
        }

        GameVars.maxValue = GameVars.startFrom;

        for (let i = 0; i < GameVars.boardDataIds.length; i++) {
            for (let j = 0; j < GameVars.boardDataIds[i].length; j++) {

                let graphic = new Phaser.GameObjects.Graphics(this.scene);
                graphic.setPosition(BoardContainer.OFF_X + j * GameConstants.CELL_SIZE, BoardContainer.OFF_Y + i * GameConstants.CELL_SIZE);
                graphic.fillStyle(0xffffff);
                graphic.fillRoundedRect(-GameConstants.CELL_SIZE_SMALL / 2, -GameConstants.CELL_SIZE_SMALL / 2, GameConstants.CELL_SIZE_SMALL, GameConstants.CELL_SIZE_SMALL, 10);
                backContainer.add(graphic);

                if (GameVars.boardDataIds[i][j] !== 0) {
                    let block = new Block(this.scene, 
                        BoardContainer.OFF_X + j * GameConstants.CELL_SIZE, 
                        BoardContainer.OFF_Y + i * GameConstants.CELL_SIZE, 
                        GameVars.boardDataIds[i][j],
                        nums[count],
                        this);
                    this.add(block);
                    this.blocks.push(block);

                    count++;
                }
            }
        }

        if (GameVars.startFrom >= 10) {
            BoardManager.addNewChains();
        }

        if (GameVars.onTutorial) {
            this.tutorialContainer = new TutorialContainer(this.scene);
            this.add(this.tutorialContainer);
        }

        this.initAnimations();
    }

    public update(): void {

        for (let i = 0; i < this.chains.length; i++) {
            this.chains[i].update();
        }

        if (GameVars.onTween) {
            return;
        }

        for (let i = 0; i < this.blocks.length; i++) {
            this.blocks[i].update();
        }
    }

    public reScale(): void {

        if (GameVars.desktop) {
            this.setScale(.75);
            this.y = GameVars.gameHeight / 2 + 85;
        } else {

            if (GameVars.scaleY > 1) {
                this.boardScale = .8;
            } else {
                this.boardScale = 1.2;
            }

            this.setScale(this.boardScale, GameVars.scaleY * this.boardScale);
            this.y = GameVars.gameHeight / 2 + 100 * GameVars.scaleY;
        }
    }

    public getBlocks(): Block[] {

        return this.blocks;
    }

    public getBlock(id: number): Block {

        for (let i = 0; i < this.blocks.length; i++) {
            if (id === this.blocks[i].id) {
                return this.blocks[i];
            }
        }
    }

    public removeBlock(block: Block): void {

        let index = this.blocks.indexOf(block);

        if (index !== -1) {
            this.blocks.splice(index, 1);
        }
    }

    public removeChain(id1: number, id2: number): void {

        for (let i = 0; i < this.chains.length; i++) {
            if (id1 === this.chains[i].blocks[0].id || id1 === this.chains[i].blocks[1].id) {

                this.chains[i].blocks[0].chainedBlock = null;
                this.chains[i].blocks[1].chainedBlock = null;

                this.getBlock(id1).chain = null;
                this.getBlock(id2).chain = null;

                let chain = this.chains[i];
                this.chains.splice(i, 1);
                chain.destroy();

                i--;
            } else if (id2 === this.chains[i].blocks[0].id || id2 === this.chains[i].blocks[1].id) {

                this.chains[i].blocks[0].chainedBlock = null;
                this.chains[i].blocks[1].chainedBlock = null;

                this.getBlock(id1).chain = null;
                this.getBlock(id2).chain = null;

                let chain = this.chains[i];
                this.chains.splice(i, 1);
                chain.destroy();

                i--;
            }
        }
    }

    public addNewBlocks(): void {
        
        let max = GameVars.maxValue - 2;
        let array = [];
        for (let i = 1; i <= max; i++) {
            array.push(i);
        }

        for (let j = 0; j < GameVars.boardDataIds[0].length; j++) {
            let i = GameVars.boardDataIds.length - 1;
            let block = new Block(this.scene, 
                BoardContainer.OFF_X + j * GameConstants.CELL_SIZE, 
                BoardContainer.OFF_Y + i * GameConstants.CELL_SIZE, 
                GameVars.boardDataIds[i][j],
                this.generateValidValue(i, j, array),
                this);
            this.add(block);
            this.blocks.push(block);
        }
    }

    public generateValidValue(i: number, j: number, array: number[]): number {

        let value = array[Math.floor(Math.random() * array.length)];

        if (GameVars.boardDataIds[i - 1][j] !== 0) {
            while (this.getBlock(GameVars.boardDataIds[i - 1][j]) && value === this.getBlock(GameVars.boardDataIds[i - 1][j]).value) {
                value = array[Math.floor(Math.random() * array.length)];
            }
        }

        return value;
    }

    public addNewChain(id1: number, id2: number): void {

        let blocks = [];

        blocks.push(this.getBlock(id1));
        blocks.push(this.getBlock(id2));

        let chain = new Chain(this.scene, blocks);
        this.add(chain);
        this.chains.push(chain);

        this.getBlock(id1).chainedBlock = this.getBlock(id2);
        this.getBlock(id2).chainedBlock = this.getBlock(id1);

        this.getBlock(id1).chain = chain;
        this.getBlock(id2).chain = chain;
    }

    public updateBlockPositions(): void {

        for (let i = 0; i < this.blocks.length; i++) {

            this.blocks[i].updatePosition();
        }
    }

    public addSpark(x: number, y: number, value: number): void {

        let sparkDown = this.scene.add.sprite(x, y + 40, "texture_atlas_1");
        this.add(sparkDown);

        sparkDown.anims.play("sparks_down");

        sparkDown.on("animationcomplete", () => {
            sparkDown.destroy();
        }, this);

        let sparkRadial = this.scene.add.sprite(x, y, "texture_atlas_1");
        sparkRadial.tint = GameConstants.SPARKS_COLOURS[value % 10 - 1]
        this.add(sparkRadial);

        sparkRadial.anims.play("sparks_radial");

        sparkRadial.on("animationcomplete", () => {
            sparkRadial.destroy();
        }, this);
    }

    public startTutorialPhaseTwo(): void {

        this.tutorialContainer.startPhaseTwo();
    }

    public startTutorialPhaseThree(): void {

        this.tutorialContainer.startPhaseThree();
    }

    public startTutorialPhaseFour(): void {

        this.tutorialContainer.startPhaseFour();
    }

    public startTutorialPhaseFive(): void {

        this.tutorialContainer.startPhaseFive();
    }

    public startTutorialPhaseSix(): void {

        this.tutorialContainer.startPhaseSix();
    }

    public showEndTutorial(): void {

        this.tutorialContainer.destroy();
    }

    public tutorialTop(): void {

        if (this.tutorialContainer) {
            this.bringToTop(this.tutorialContainer);
        }
    }

    private initAnimations(): void {

        this.scene.anims.create({ key: "sparks_down", frames: this.scene.anims.generateFrameNames( "texture_atlas_1", { prefix: "sparks_down_", start: 1, end: 19, zeroPad: 2, suffix: ""}), frameRate: 30});
        this.scene.anims.create({ key: "sparks_radial", frames: this.scene.anims.generateFrameNames( "texture_atlas_1", { prefix: "sparks_radial_", start: 1, end: 23, zeroPad: 2, suffix: ""}), frameRate: 30});
    }
}
