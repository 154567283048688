import { GameManager } from "../GameManager";
import { GameConstants } from "../GameConstants";
import { GameVars } from "../GameVars";
import { BootScene } from "./BootScene";
import { Game } from "../Game";

export class PreloadScene extends Phaser.Scene {

    public static currentInstance: PreloadScene;

    private progressBar: Phaser.GameObjects.Image;
    private scaledItemsContainer: Phaser.GameObjects.Container;

    constructor() {

        super("PreloadScene");

        PreloadScene.currentInstance = this;
    }

    public preload(): void {

        this.composeScene();

        this.loadAssets();
    }

    public create(): void {
        this.time.delayedCall(200, function (): void {
            GameManager.onGameAssetsLoaded();
        }, [], this);
    }

    private composeScene(): void {

        const background_1 = this.add.graphics();
        background_1.fillStyle(GameConstants.BACKGROUND_COLOR);
        background_1.fillRect(0, 0, GameVars.gameWidth, GameVars.gameHeight);

        const background_2 = this.add.image(0, GameVars.gameHeight, "bg");
        background_2.setOrigin(0, 1);
        background_2.scaleY = GameVars.scaleY;

        this.scaledItemsContainer = this.add.container(GameVars.gameWidth / 2, 560);
        this.scaledItemsContainer.scaleY = GameVars.scaleY;

        const barWidth = 550;
        const barWHeight = 30;
        const borderWidth = 6;

        const preloadBarCapsuleBorder = new Phaser.GameObjects.Graphics(this);
        preloadBarCapsuleBorder.fillStyle(0xffffff);
        preloadBarCapsuleBorder.fillRect(0, 0, barWidth + borderWidth * 2, barWHeight + borderWidth * 2);
        preloadBarCapsuleBorder.lineStyle(4, 0x2c5a70);
        preloadBarCapsuleBorder.strokeRect(0, 0, barWidth + borderWidth * 2, barWHeight + borderWidth * 2);
        preloadBarCapsuleBorder.x = - barWidth / 2 - borderWidth;
        preloadBarCapsuleBorder.y = -borderWidth;
        this.scaledItemsContainer.add(preloadBarCapsuleBorder);

        const preloadBarCapsule = new Phaser.GameObjects.Graphics(this);
        preloadBarCapsule.fillStyle(0xffffff);
        preloadBarCapsule.fillRect(0, 0, barWidth, barWHeight);
        preloadBarCapsule.x = - barWidth / 2;
        this.scaledItemsContainer.add(preloadBarCapsule);

        this.progressBar = new Phaser.GameObjects.Image(this, - barWidth / 2, 0, "gradient_preload");
        this.progressBar.setOrigin(0);
        this.progressBar.scaleY = barWHeight / 256;
        this.progressBar.scaleX = 0;
        this.scaledItemsContainer.add(this.progressBar);
    }

    private loadAssets(): void {

        this.load.atlas("texture_atlas_1", "assets/texture_atlas_1.png", "assets/texture_atlas_1.json");

        this.load.on("progress", this.updateLoadedPercentage, this);
    }

    private updateLoadedPercentage(percentageLoaded: number): void {

        if (this.progressBar.scaleX < percentageLoaded) {
            this.progressBar.scaleX = (percentageLoaded * 550) / 8;
        }
    }
}
