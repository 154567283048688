import { GameConstants } from "./../../GameConstants";
import { GameVars } from "./../../GameVars";

export class HUD extends Phaser.GameObjects.Container {

    private timer: Phaser.GameObjects.Graphics;
    private timerBack: Phaser.GameObjects.Image;

    private highscore: Phaser.GameObjects.Text;
    private score: Phaser.GameObjects.Text;

    private combo: Phaser.GameObjects.Text;

    constructor(scene: Phaser.Scene) {

        super(scene);

        if (GameVars.desktop) {
            this.setScale(.82);
        } else {
            this.scaleY = GameVars.scaleY;
        }

        const headerBack = new Phaser.GameObjects.Image(this.scene, 0, 0, "gradient_header");
        headerBack.setOrigin(0);
        headerBack.setScale(GameVars.gameWidth / 8, 135 / 256);
        this.add(headerBack);

        const header = new Phaser.GameObjects.Image(this.scene, GameVars.desktop ? GameVars.gameWidth / 2 + 120 : GameVars.gameWidth / 2 + 53, 15, "texture_atlas_1", "header");
        header.setOrigin(.5, 0);
        this.add(header);

        this.highscore = new Phaser.GameObjects.Text(this.scene, GameVars.desktop ? 490 : 295, 57, GameVars.gameData.highscore.toString(), { fontFamily: "TitanOne", fontSize: "50px", color: "#265C80" });
        this.highscore.setOrigin(.5);
        this.add(this.highscore);

        this.score = new Phaser.GameObjects.Text(this.scene, GameVars.desktop ? 775 : 585, 57, GameVars.score.toString(), { fontFamily: "TitanOne", fontSize: "50px", color: "#265C80" });
        this.score.setOrigin(.5);
        this.add(this.score);

        this.timer = new Phaser.GameObjects.Graphics(this.scene);
        this.timer.setPosition(GameVars.desktop ? 410 : 175, 180);
        this.timer.fillStyle(0xfb9620);
        this.timer.fillRect(0, -10, 460, 20);
        this.add(this.timer);

        this.timerBack = new Phaser.GameObjects.Image(this.scene, GameVars.desktop ? GameVars.gameWidth / 2 + 110 : GameVars.gameWidth / 2, 180, "texture_atlas_1", "timer");
        this.add(this.timerBack);

        this.combo = new Phaser.GameObjects.Text(this.scene, GameVars.desktop ? GameVars.gameWidth / 2 + 110 : GameVars.gameWidth / 2, 0, "COMBO x 1", { fontFamily: "Baloo", fontSize: "70px", color: "#265C80" });
        this.combo.setStroke("#FBDB75", 8);
        this.combo.visible = false;
        this.combo.setOrigin(.5);
        this.combo.y = (GameVars.gameHeight / 2) / GameVars.scaleY - (GameVars.desktop ? 0 : 120);
        this.add(this.combo);

        if (GameVars.onTutorial) {
            this.timer.visible = false;
            this.timerBack.visible = false;
        }
    }

    public reScale(): void {

        if (GameVars.desktop) {
            this.setScale(.82);
        } else {
            this.scaleY = GameVars.scaleY;
        }
    }

    public showTimer(): void {

        this.timer.visible = true;
        this.timer.alpha = 0;
        this.timerBack.visible = true;
        this.timerBack.alpha = 0;

        this.scene.tweens.add({
            targets: [this.timer, this.timerBack],
            alpha: 1,
            ease: Phaser.Math.Easing.Cubic.Out,
            duration: 500
        });
    }

    public updateCombo(): void {

        this.combo.alpha = 1;
        this.combo.visible = true;
        this.combo.setText("COMBO x " + GameVars.combo);
        this.combo.setScale(.95 + GameVars.combo * .05);
    }

    public hideCombo(): void {

        this.scene.tweens.add({
            targets: this.combo,
            alpha: 0,
            ease: Phaser.Math.Easing.Linear,
            duration: 500,
            onComplete: () => {
                this.combo.visible = false;
            },
            onCompleteScope: this
        });
    }

    public update(): void {

        this.timer.scaleX = 1 - (GameVars.timer / (GameConstants.MAX_TIME + (GameVars.maxValue * 10)));
        this.score.setText(GameVars.score.toString());
        this.highscore.setText(GameVars.gameData.highscore.toString());
    }
}
