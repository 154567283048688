import { GameManager } from "./../../GameManager";
import { GameVars } from "../../GameVars";
import { Button } from "../../utils/Utils";
import { BoardManager } from "./BoardManager";
import { BackgroundMenu } from "./BackgroundMenu";

export class MenuLayer extends Phaser.GameObjects.Container {

    private backBtn: Button;
    private midContainer: Phaser.GameObjects.Container;

    constructor(scene: Phaser.Scene) {

        super(scene);

        this.visible = false;

        const bck = new Phaser.GameObjects.Image(this.scene, 0, 0, "gradient_bck");
        bck.alpha = .7;
        bck.setOrigin(0);
        bck.setScale(GameVars.gameWidth / 16, GameVars.gameHeight / 256);
        this.add(bck);

        this.backBtn = new Button(this.scene, GameVars.desktop ? 55 : 70, GameVars.desktop ? 55 : 70 * GameVars.scaleY, "texture_atlas_1", "btn_back", "btn_back_hover");
        this.backBtn.onUp(this.onClickBack, this);
        this.backBtn.scaleY = GameVars.scaleY;
        this.add(this.backBtn);

        this.midContainer = new Phaser.GameObjects.Container(this.scene, GameVars.gameWidth / 2, GameVars.gameHeight / 2 + (GameVars.desktop ? 70 : 100 * GameVars.scaleY));
        this.midContainer.scaleY = GameVars.scaleY;
        this.add(this.midContainer);

        let title = new Phaser.GameObjects.Image(this.scene, 0, -360, "texture_atlas_1", "txt_pause");
        this.midContainer.add(title);

        let midBck = new BackgroundMenu(this.scene, 0, 0, 600);
        this.midContainer.add(midBck);

        let continueBck = new Phaser.GameObjects.Graphics(this.scene);
        continueBck.setPosition(0, -120);
        continueBck.fillStyle(0xffffff);
        continueBck.fillRoundedRect(-170, -35, 340, 70, 35);
        continueBck.setInteractive(new Phaser.Geom.Rectangle(-170, -35, 340, 70), Phaser.Geom.Rectangle.Contains);
        continueBck.on("pointerdown", this.onClickBack, this);
        this.midContainer.add(continueBck);

        let continueBtn = new Button(this.scene, -140, -120, "texture_atlas_1", "btn_next", "btn_next_hover");
        continueBtn.onDown(this.onClickBack, this);
        this.midContainer.add(continueBtn);

        let continueText = new Phaser.GameObjects.Text(this.scene, 20, -122, "Continue", { fontFamily: "Baloo", fontSize: "45px", color: "#265C80" });
        continueText.setOrigin(.5);
        this.midContainer.add(continueText);

        let restartBck = new Phaser.GameObjects.Graphics(this.scene);
        restartBck.setPosition(0, 0);
        restartBck.fillStyle(0xffffff);
        restartBck.fillRoundedRect(-170, -35, 340, 70, 35);
        restartBck.setInteractive(new Phaser.Geom.Rectangle(-170, -35, 340, 70), Phaser.Geom.Rectangle.Contains);
        restartBck.on("pointerdown", this.onRestartDown, this);
        this.midContainer.add(restartBck);

        let restartBtn = new Button(this.scene, -140, 0, "texture_atlas_1", "btn_refresh", "btn_refresh_hover");
        restartBtn.onDown(this.onRestartDown, this);
        this.midContainer.add(restartBtn);

        let restartText = new Phaser.GameObjects.Text(this.scene, 20, -2, "Restart", { fontFamily: "Baloo", fontSize: "45px", color: "#265C80" });
        restartText.setOrigin(.5);
        this.midContainer.add(restartText);

        let homeBck = new Phaser.GameObjects.Graphics(this.scene);
        homeBck.setPosition(0, 120);
        homeBck.fillStyle(0xffffff);
        homeBck.fillRoundedRect(-170, -35, 340, 70, 35);
        homeBck.setInteractive(new Phaser.Geom.Rectangle(-170, -35, 340, 70), Phaser.Geom.Rectangle.Contains);
        homeBck.on("pointerdown", this.onHomeDown, this);
        this.midContainer.add(homeBck);

        let homeBtn = new Button(this.scene, -140, 120, "texture_atlas_1", "btn_home", "btn_home_hover");
        homeBtn.onDown(this.onHomeDown, this);
        this.midContainer.add(homeBtn);

        let homeText = new Phaser.GameObjects.Text(this.scene, 20, 118, "Home", { fontFamily: "Baloo", fontSize: "45px", color: "#265C80" });
        homeText.setOrigin(.5);
        this.midContainer.add(homeText);
    }

    public rescale(): void {

        this.backBtn.y = 70 * GameVars.scaleY;
        this.backBtn.scaleY = GameVars.scaleY;
        this.midContainer.scaleY = GameVars.scaleY;
    }

    private onRestartDown(): void {
        GAMESNACKS.gameOver();
        if (GameVars.startFrom === 1 && !GameVars.onTutorial) {
            GameManager.setStartFrom(5);
        }

        GameManager.enterBoardScene(GameVars.onTutorial);
    }

    private onHomeDown(): void {
        GAMESNACKS.gameOver();

        BoardManager.tutorialEnd();

        GameManager.enterSplashScene();
    }

    private onClickBack(): void {

        BoardManager.hideMenu();
    }
}
