import { EndTutorialLayer } from "./EndTutorialLayer";
import { WinLayer } from "./WinLayer";
import { GameOverLayer } from "./GameOverLayer";
import { GameVars } from "./../../GameVars";
import { GameConstants } from "../../GameConstants";
import { GameManager } from "../../GameManager";
import { HUD } from "./HUD";
import { GUI } from "./GUI";
import { BoardManager } from "./BoardManager";
import { BoardContainer } from "./board-container/BoardContainer";
import { MenuLayer } from "./MenuLayer";

export class BoardScene extends Phaser.Scene {

    public static currentInstance: BoardScene;

    private gui: GUI;
    private hud: HUD;
    private boardContainer: BoardContainer;
    private menuLayer: MenuLayer;
    private gameOverLayer: GameOverLayer;
    private winLayer: WinLayer;

    private spacebar: Phaser.Input.Keyboard.Key;
    
    constructor() {

        super("BoardScene");
        
        BoardScene.currentInstance = this;
    }

    public create(): void {

        BoardManager.init();

        GameManager.setCurrentScene(this);

        const bck = this.add.graphics();
        bck.fillStyle(GameConstants.BACKGROUND_COLOR);
        bck.fillRect(0, 0, GameVars.gameWidth, GameVars.gameHeight);

        const bck2 = this.add.image(0, GameVars.gameHeight, "bg");
        bck2.scaleY = GameVars.scaleY;
        bck2.setOrigin(0, 1);

        this.boardContainer = new BoardContainer(this);
        this.add.existing(this.boardContainer);

        this.hud = new HUD(this);
        this.add.existing(this.hud);

        this.gui = new GUI(this);
        this.add.existing(this.gui);

        this.menuLayer = new MenuLayer(this);
        this.add.existing(this.menuLayer);

        this.winLayer = new WinLayer(this);
        this.add.existing(this.winLayer);

        this.cameras.main.fadeIn(300, 255, 255, 255);

        if (GameVars.desktop) {
            this.spacebar = this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.SPACE);
        } 
    }

    public update(): void {

        if (GameVars.desktop) {
            if (Phaser.Input.Keyboard.JustDown(this.spacebar)) {

                if (GameVars.paused) {
                    BoardManager.hideMenu();
                } else {
                    BoardManager.showMenu();
                }
            }
        }

        if (GameVars.paused || !GameVars.correctOrientation) {
            return;
        }
        
        this.boardContainer.update();
        this.hud.update();

        BoardManager.update();
    }

    public showMenu(): void {

        this.menuLayer.visible = true;
    }

    public hideMenu(): void {

        this.menuLayer.visible =  false;
    }

    public showGameOver(): void {

        this.gameOverLayer = new GameOverLayer(this);
        this.add.existing(this.gameOverLayer);
    }

    public showWin(): void {

        this.winLayer.show();
    }

    public showEndTutorial(): void {

        this.boardContainer.showEndTutorial();

        let endTutorialLayer = new EndTutorialLayer(this);
        this.add.existing(endTutorialLayer);
    }

    public tutorialEnd(): void {

        this.hud.showTimer();
        this.gui.showMenu();
    }

    public updateCombo(): void {

        this.hud.updateCombo();
    }

    public hideCombo(): void {

        this.hud.hideCombo();
    }

    public reScale(): void {

        this.menuLayer.rescale();
        this.hud.reScale();
        this.gui.reScale();
        this.boardContainer.reScale();
    }
}
