export class GameConstants {

    public static readonly VERSION = "0.0";
    public static readonly DEVELOPMENT = false;
    public static readonly VERBOSE = false;
    public static readonly GAME_WIDTH = 768;
    public static readonly GAME_HEIGHT = 1024;

    public static readonly CELL_SIZE = 87;
    public static readonly CELL_SIZE_SMALL = 75;

    public static readonly MAX_NUMBER = 30;

    public static readonly MAX_TIME = 700;

    public static BACKGROUND_COLOR = 0XDAE9F1;

    public static SPARKS_COLOURS = [
        0xffffff,
        0xC9DD24,
        0x69D3FF,
        0xFF6F8F,
        0xFFD700,
        0xF6414F,
        0x48AC23,
        0x2093EC,
        0xAB4EFA
    ];

    public static readonly SAVED_GAME_DATA_KEY = "numbers-merge-data";
}
