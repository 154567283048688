import { GameVars } from "../../GameVars";
import { Button } from "../../utils/Utils";
import { BackgroundMenu } from "./BackgroundMenu";
import { GameManager } from "../../GameManager";
import { StartFromLayer } from "../splash-scene/StartFromLayer";

export class GameOverLayer extends Phaser.GameObjects.Container {

    constructor(scene: Phaser.Scene) {
        
        super(scene);

        const bck = new Phaser.GameObjects.Image(this.scene, 0, 0, "gradient_bck");
        bck.alpha = .7;
        bck.setOrigin(0);
        bck.setScale(GameVars.gameWidth / 16, GameVars.gameHeight / 256);
        bck.setInteractive();
        bck.on("pointerdown", () => {
            //
        }, this);
        this.add(bck);

        let midContainer = new Phaser.GameObjects.Container(this.scene, GameVars.gameWidth / 2, GameVars.gameHeight / 2 + (GameVars.desktop ? 70 : 100 * GameVars.scaleY));
        midContainer.scaleY = GameVars.scaleY;
        this.add(midContainer);

        let title = new Phaser.GameObjects.Image(this.scene, 0, -360, "texture_atlas_1", "txt_game-over");
        midContainer.add(title);

        let midBck = new BackgroundMenu(this.scene, 0, 0, 600);
        midContainer.add(midBck);

        const startFromLayer = new StartFromLayer(this.scene, true);
        startFromLayer.setPosition(0, -175);
        midContainer.add(startFromLayer);

        let restartBck = new Phaser.GameObjects.Graphics(this.scene);
        restartBck.setPosition(0, 80);
        restartBck.fillStyle(0xffffff);
        restartBck.fillRoundedRect(-170, -35, 340, 70, 35);
        restartBck.setInteractive(new Phaser.Geom.Rectangle(-170, -35, 340, 70), Phaser.Geom.Rectangle.Contains);
        restartBck.on("pointerdown", this.onRestartDown, this);
        midContainer.add(restartBck);

        let restartBtn = new Button(this.scene, -140, 80, "texture_atlas_1", "btn_refresh", "btn_refresh_hover");
        restartBtn.onDown(this.onRestartDown, this);
        midContainer.add(restartBtn);

        let restartText = new Phaser.GameObjects.Text(this.scene, 20, 78, "Restart", {fontFamily: "Baloo", fontSize: "45px", color: "#265C80"});
        restartText.setOrigin(.5);
        midContainer.add(restartText);

        let homeBck = new Phaser.GameObjects.Graphics(this.scene);
        homeBck.setPosition(0, 200);
        homeBck.fillStyle(0xffffff);
        homeBck.fillRoundedRect(-170, -35, 340, 70, 35);
        homeBck.setInteractive(new Phaser.Geom.Rectangle(-170, -35, 340, 70), Phaser.Geom.Rectangle.Contains);
        homeBck.on("pointerdown", this.onHomeDown, this);
        midContainer.add(homeBck);

        let homeBtn = new Button(this.scene, -140, 200, "texture_atlas_1", "btn_home", "btn_home_hover");
        homeBtn.onDown(this.onHomeDown, this);
        midContainer.add(homeBtn);

        let homeText = new Phaser.GameObjects.Text(this.scene, 20, 198, "Home", {fontFamily: "Baloo", fontSize: "45px", color: "#265C80"});
        homeText.setOrigin(.5);
        midContainer.add(homeText);

        this.alpha = 0;

        this.scene.tweens.add({
            targets: this,
            alpha: 1,
            ease: Phaser.Math.Easing.Cubic.Out,
            duration: 500
        });
    }

    private onRestartDown(): void {

        GameManager.enterBoardScene(false); 
    }

    private onHomeDown(): void {

        GameManager.enterSplashScene(); 
    }
}
