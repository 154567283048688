import { Tilemaps } from "phaser";

export class Logo extends Phaser.GameObjects.Container {

    private static BLOCK_1_POSITION = {x: -15, y: -30};
    private static BLOCK_7_POSITION = {x: -30, y: 15};
    private static BLOCK_9_POSITION = {x: 20, y: 30};

    private block1: Phaser.GameObjects.Image;
    private block7: Phaser.GameObjects.Image;
    private block9: Phaser.GameObjects.Image;

    private a1: number;
    private a7: number;
    private a9: number;

    constructor(scene: Phaser.Scene) {
        
        super(scene);

        this.y = 250;

        this.a1 = 0;
        this.a7 = .2;
        this.a9 = 2;

        const img1 = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", "logo_01");
        img1.setScale(2);
        img1.alpha = 0;
        this.add(img1);

        const img2 = new Phaser.GameObjects.Image(this.scene, 0, -1000, "texture_atlas_1", "logo_02");
        this.add(img2);

        const img3 = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", "logo_03");
        img3.setScale(2);
        img3.alpha = 0;
        this.add(img3);

        this.scene.tweens.add({
            targets: img1,
            alpha: 1,
            scaleX: 1,
            scaleY: 1,
            ease: Phaser.Math.Easing.Cubic.Out,
            duration: 500,
            delay: 500
        });

        this.scene.tweens.add({
            targets: img3,
            alpha: 1,
            scaleX: 1,
            scaleY: 1,
            ease: Phaser.Math.Easing.Cubic.Out,
            duration: 500,
            delay: 1000
        });

       this.scene.tweens.add({
            targets: img2,
            y: 0,
            ease: Phaser.Math.Easing.Bounce.Out,
            duration: 1000,
            delay: 1500
        });

        const movingBlocksContainer = new Phaser.GameObjects.Container(this.scene);
        movingBlocksContainer.x = 230;
        movingBlocksContainer.y = -80;
        movingBlocksContainer.alpha = 0;
        this.add(movingBlocksContainer);

        this.scene.tweens.add({
            targets: movingBlocksContainer,
            alpha: 1,
            ease: Phaser.Math.Easing.Cubic.Out,
            duration: 800,
            delay: 1500
        });

        this.block1 = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", "logo_number_bot");
        movingBlocksContainer.add(this.block1);

        this.block7 = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", "logo_number_mid");
        movingBlocksContainer.add(this.block7);

        this.block9 = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", "logo_number_top");
        movingBlocksContainer.add(this.block9);

    }

    public update(): void {
        
        this.block1.x = Logo.BLOCK_1_POSITION.x + 80 * Math.cos(this.a1);
        this.block1.y = Logo.BLOCK_1_POSITION.y + 40 * Math.sin(this.a1);

        this.a1 += .01;

        this.block7.x = Logo.BLOCK_7_POSITION.x + 40 * Math.cos(this.a7);
        this.block7.y = Logo.BLOCK_7_POSITION.y + 60 * Math.sin(this.a7);

        this.a7 -= .005;

        this.block9.x = Logo.BLOCK_9_POSITION.x + 60 * Math.cos(this.a9);
        this.block9.y = Logo.BLOCK_9_POSITION.y + 40 * Math.sin(this.a9);

        this.a9 += .015;
    }
}
