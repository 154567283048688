import { GameManager } from "../GameManager";
import { Game } from "../Game";
import { GameVars } from "../GameVars";

export class BootScene extends Phaser.Scene {

    public static currentInstance: BootScene;

    constructor() {

        super("BootScene");
    }

    public preload(): void {

        this.createGradients();

        this.load.image("bg", "assets/bg.png");
    }

    public create(): void {

        this.add.text(-100, -100, "abcdefg", { fontFamily: "TitanOne", fontSize: 28, color: "#A6F834" });
        this.add.text(-100, -100, "abcdefg", { fontFamily: "Baloo", fontSize: 28, color: "#A6F834" });

        BootScene.currentInstance = this;
        GameManager.setCurrentScene(this);

        this.scene.setVisible(false);

        if (GameVars.desktop) {
            GameManager.init();
        } else {

            if (window.innerWidth > window.innerHeight) {
                document.getElementById("orientation").style.display = "block";
                document.getElementById("content").style.display = "none";
            } else {

                if (navigator.userAgent.indexOf("Safari") > -1) {
                    document.getElementById("content").style.height = Math.floor(window.innerHeight) + "px";
                }

                GameManager.init();
            }
        }
    }

    private createGradients(): void {

        var texture = this.textures.createCanvas("gradient_bck", 16, 256);
        var context = texture.getContext();
        var grd = context.createLinearGradient(0, 0, 0, 256);

        grd.addColorStop(0, "#9A9B9D");
        grd.addColorStop(1, "#08080A");

        context.fillStyle = grd;
        context.fillRect(0, 0, 16, 256);

        texture.refresh();

        texture = this.textures.createCanvas("gradient_header", 16, 256);
        context = texture.getContext();
        grd = context.createLinearGradient(0, 0, 0, 256);

        grd.addColorStop(0, "#C6D6DD");
        grd.addColorStop(1, "#A4B5BD");

        context.fillStyle = grd;
        context.fillRect(0, 0, 16, 256);

        texture.refresh();

        texture = this.textures.createCanvas("gradient_preload", 16, 256);
        context = texture.getContext();
        grd = context.createLinearGradient(0, 0, 0, 256);

        grd.addColorStop(0, "#67DECC");
        grd.addColorStop(1, "#0FB0CC");

        context.fillStyle = grd;
        context.fillRect(0, 0, 16, 256);

        texture.refresh();
    }
}
