import { GameManager } from "./../../GameManager";
import { GameVars } from "./../../GameVars";

export class StartFromLayer extends Phaser.GameObjects.Container {

    private tick: Phaser.GameObjects.Image;
    private from5: Phaser.GameObjects.Image;
    private from10: Phaser.GameObjects.Image;
    private from15: Phaser.GameObjects.Image;
    private from20: Phaser.GameObjects.Image;
    private from20frame: Phaser.GameObjects.Image;
    private from25: Phaser.GameObjects.Image;
    private from25frame: Phaser.GameObjects.Image;

    constructor(scene: Phaser.Scene, skipIntro?: boolean) {

        super(scene);

        const text = new Phaser.GameObjects.Text(this.scene, 0, 0, "Start from", {fontFamily: "Baloo", fontSize: "40px", color: "#265C80"});
        text.setOrigin(.5);
        this.add(text);

        this.from5 = new Phaser.GameObjects.Image(this.scene, -200, 80, "texture_atlas_1", "block_5");
        this.from5.alpha = .5;
        this.add(this.from5);

        this.from10 = new Phaser.GameObjects.Image(this.scene, -100, 80, "texture_atlas_1", "block_10");
        this.from10.alpha = .5;
        this.add(this.from10);

        this.from15 = new Phaser.GameObjects.Image(this.scene, 0, 80, "texture_atlas_1", "block_15");
        this.from15.alpha = .5;
        this.add(this.from15);

        this.from20 = new Phaser.GameObjects.Image(this.scene, 100, 80, "texture_atlas_1", "block_20");
        this.from20.alpha = .5;
        this.add(this.from20);

        this.from20frame = new Phaser.GameObjects.Image(this.scene, 100, 80, "texture_atlas_1", "block_frame");
        this.from20frame.alpha = .5;
        this.add(this.from20frame);

        this.from25 = new Phaser.GameObjects.Image(this.scene, 200, 80, "texture_atlas_1", "block_25");
        this.from25.alpha = .5;
        this.add(this.from25);

        this.from25frame = new Phaser.GameObjects.Image(this.scene, 200, 80, "texture_atlas_1", "block_frame");
        this.from25frame.alpha = .5;
        this.add(this.from25frame);

        this.tick = new Phaser.GameObjects.Image(this.scene, this.from5.x, this.from5.y, "texture_atlas_1", "btn_start_selected");
        this.add(this.tick);

        if (GameVars.gameData.bestValue >= 5) {
            this.from5.alpha = 1;
            this.from5.setInteractive();
            this.from5.on("pointerdown", this.on5Down, this);
        }

        if (GameVars.gameData.bestValue >= 10) {
            this.from10.alpha = 1;
            this.from10.setInteractive();
            this.from10.on("pointerdown", this.on10Down, this);
        }

        if (GameVars.gameData.bestValue >= 15) {
            this.from15.alpha = 1;
            this.from15.setInteractive();
            this.from15.on("pointerdown", this.on15Down, this);
        }

        if (GameVars.gameData.bestValue >= 20) {
            this.from20.alpha = 1;
            this.from20frame.alpha = 1;
            this.from20.setInteractive();
            this.from20.on("pointerdown", this.on20Down, this);
        }

        if (GameVars.gameData.bestValue >= 25) {
            this.from25.alpha = 1;
            this.from25frame.alpha = 1;
            this.from25.setInteractive();
            this.from25.on("pointerdown", this.on25Down, this);
        }

        if (!skipIntro) {
            this.alpha = 0;

            this.scene.tweens.add({
                targets: this,
                alpha: 1,
                ease: Phaser.Math.Easing.Cubic.Out,
                duration: 500,
                delay: 2500
            });
        }

        if (GameVars.startFrom === 5) {
            this.on5Down();
        } else if (GameVars.startFrom === 10) {
            this.on10Down();
        } else if (GameVars.startFrom === 15) {
            this.on15Down();
        } else if (GameVars.startFrom === 20) {
            this.on20Down();
        } else if (GameVars.startFrom === 25) {
            this.on25Down();
        }
    }

    private on5Down(): void {
        
        GameManager.setStartFrom(5);

        this.tick.setPosition(this.from5.x, this.from5.y);
    }

    private on10Down(): void {
        
        GameManager.setStartFrom(10);

        this.tick.setPosition(this.from10.x, this.from10.y);
    }

    private on15Down(): void {
        
        GameManager.setStartFrom(15);

        this.tick.setPosition(this.from15.x, this.from15.y);
    }

    private on20Down(): void {
        
        GameManager.setStartFrom(20);

        this.tick.setPosition(this.from20.x, this.from20.y);
    }

    private on25Down(): void {
        
        GameManager.setStartFrom(25);

        this.tick.setPosition(this.from25.x, this.from25.y);
    }
}
