export class BackgroundMenu extends Phaser.GameObjects.Graphics {

    constructor(scene: Phaser.Scene, x: number, y: number, height: number) {

        super(scene);

        this.x = x;
        this.y = y;

        this.fillStyle(0x607E8C);
        this.fillRoundedRect(-297, -height / 2, 594, height, 80);

        height -= 16;

        this.fillStyle(0xC6D6DD);
        this.fillRoundedRect(-289, -height / 2, 578, height, 75);

        height -= 50;

        this.fillStyle(0xE6EDF0);
        this.fillRoundedRect(-264, -height / 2, 528, height, 55);
    }
}
