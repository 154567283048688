import { BoardManager } from "./BoardManager";
import { GameVars } from "../../GameVars";
import { Button } from "../../utils/Utils";

export class GUI extends Phaser.GameObjects.Container {

    private menuBtn: Button;

    constructor(scene: Phaser.Scene) {
        
        super(scene);

        this.scaleY = GameVars.scaleY;

        this.menuBtn = new Button(this.scene, GameVars.desktop ? 55 : 70, GameVars.desktop ? 55 : 70, "texture_atlas_1", "btn_menu", "btn_menu_hover");
        this.menuBtn.onUp(this.onClickMenu, this);
        this.add(this.menuBtn);
    }

    public reScale(): void {

        this.scaleY = GameVars.scaleY;
    }

    public showMenu(): void {

        this.menuBtn.visible = true;
        this.menuBtn.alpha = 0;

        this.scene.tweens.add({
            targets: this.menuBtn,
            alpha: 1,
            ease: Phaser.Math.Easing.Cubic.Out,
            duration: 500
        });
    }

    private onClickMenu(): void {

        BoardManager.showMenu();
    }
}
