import { BoardManager } from "./BoardManager";
import { GameVars } from "./../../GameVars";
import { BackgroundMenu } from "./BackgroundMenu";

export class EndTutorialLayer extends Phaser.GameObjects.Container {

    constructor(scene: Phaser.Scene) {

        super(scene);

        const bck = new Phaser.GameObjects.Image(this.scene, 0, 0, "gradient_bck");
        bck.alpha = .5;
        bck.setOrigin(0);
        bck.setScale(GameVars.gameWidth / 16, GameVars.gameHeight / 256);
        bck.setInteractive();
        bck.on("pointerdown", () => {
            BoardManager.tutorialEnd();
            this.destroy();
        }, this);
        this.add(bck);

        const midContainer = new Phaser.GameObjects.Container(this.scene);
        midContainer.setPosition(GameVars.gameWidth / 2, GameVars.gameHeight / 2);
        midContainer.scaleY = GameVars.scaleY;
        this.add(midContainer);

        let midBck = new BackgroundMenu(this.scene, 0, -70, 300);
        midContainer.add(midBck);

        const topText = new Phaser.GameObjects.Text(this.scene, 0, -150, "Can you merge to", { fontFamily: "Baloo", fontSize: "60px", color: "#265C80", align: "center" });
        topText.setOrigin(.5);
        midContainer.add(topText);

        const block = new Phaser.GameObjects.Image(this.scene, 0, -30, "texture_atlas_1", "block_30");
        block.setScale(1.5);
        midContainer.add(block);

        const frame = new Phaser.GameObjects.Image(this.scene, 0, -30, "texture_atlas_1", "block_frame");
        frame.setScale(1.5);
        midContainer.add(frame);

        const questionMark = new Phaser.GameObjects.Text(this.scene, 110, -40, "?", { fontFamily: "Baloo", fontSize: "130px", color: "#265C80", align: "center" });
        questionMark.setOrigin(.5);
        midContainer.add(questionMark);

        const continueText = new Phaser.GameObjects.Text(this.scene, 0, 160, "Tap to continue", { fontFamily: "Baloo", fontSize: "70px", color: "#265C80", align: "center" });
        continueText.setOrigin(.5);
        continueText.setStroke("#FBDB75", 10);
        midContainer.add(continueText);

        this.scene.tweens.add({
            targets: continueText,
            scaleX: 1.05,
            scaleY: 1.05,
            ease: Phaser.Math.Easing.Linear,
            duration: 600,
            yoyo: true,
            repeat: -1
        });

        this.alpha = 0;

        this.scene.tweens.add({
            targets: this,
            alpha: 1,
            ease: Phaser.Math.Easing.Linear,
            duration: 500
        });
    }
}
