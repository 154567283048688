import { GameConstants } from "./GameConstants";
import { GameVars } from "./GameVars";

export class GameManager {

    public static init(): void {

        GameVars.intitialised = true;
        GameVars.startFrom = 5;
        GameVars.correctOrientation = true;

        if (GameVars.desktop) {

            GameVars.scaleY = 1;

        } else {

            GameVars.currentScene.game.scale.displaySize = GameVars.currentScene.game.scale.parentSize;
            GameVars.currentScene.game.scale.refresh();


            const aspectRatio = window.innerHeight / window.innerWidth;
            GameVars.scaleY = (GameVars.gameHeight / GameVars.gameWidth) / aspectRatio;
        }

        GameManager.readGameData();
    }

    public static setStartFrom(value: number): void {

        GameVars.startFrom = value;
    }

    public static readGameData(): void {
        GameManager.getGameStorageData(
            GameConstants.SAVED_GAME_DATA_KEY,
            function (gameData: string): void {

                if (gameData) {
                    GameVars.gameData = JSON.parse(gameData);
                } else {
                    GameVars.gameData = {
                        muted: false,
                        highscore: 0,
                        bestValue: 0,
                        startTutorial: true
                    };
                }
                GameVars.gameData.muted = GAMESNACKS.isAudioEnabled();
                GAMESNACKS.subscribeToAudioUpdates(() => {
                    GameVars.gameData.muted = GAMESNACKS.isAudioEnabled();
                });
                GameManager.startGame();
            }
        );
    }

    public static restoreGameData(): void {

        GameVars.gameData = {
            muted: false,
            highscore: 0,
            bestValue: 0,
            startTutorial: true
        };

        GameManager.writeGameData();
    }

    public static setCurrentScene(scene: Phaser.Scene): void {

        GameVars.currentScene = scene;
    }

    public static onGameAssetsLoaded(): void {
        GAMESNACKS.gameReady();
        if (GameVars.gameData.startTutorial) {
            GameManager.enterBoardScene(true);
        } else {
            GameManager.enterSplashScene();
            // GameManager.enterBoardScene(false);
        }
    }

    public static enterSplashScene(): void {

        GameManager.setStartFrom(5);

        GameVars.currentScene.scene.start("SplashScene");
    }

    public static enterLevelSelectionScene(): void {

        GameVars.currentScene.scene.start("LevelSelectionScene");
    }

    public static enterBoardScene(tutorial: boolean): void {

        GameVars.onTutorial = tutorial;

        GameVars.currentScene.scene.start("BoardScene");
    }

    public static writeGameData(): void {

        GameManager.setGameStorageData(
            GameConstants.SAVED_GAME_DATA_KEY,
            GameVars.gameData,
            function (): void {
                GameManager.log("game data successfully saved");
            }
        );

    }

    public static log(text: string, error?: Error): void {

        if (!GameConstants.VERBOSE) {
            return;
        }

        if (error) {
            console.error(text + ":", error);
        } else {
            console.log(text);
        }
    }

    private static startGame(): void {

        GameVars.currentScene.scene.start("PreloadScene");
    }

    private static getGameStorageData(key: string, successCb: Function): void {

        const gameDataStr = localStorage.getItem(key);
        successCb(gameDataStr);
    }

    private static setGameStorageData(key: string, value: any, successCb: Function): void {

        localStorage.setItem(key, JSON.stringify(value));
        successCb();
    }
}
