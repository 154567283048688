import { StartFromLayer } from "./StartFromLayer";
import { GameVars } from "../../GameVars";
import { Button } from "../../utils/Utils";
import { GameConstants } from "../../GameConstants";
import { GameManager } from "../../GameManager";
import { BootScene } from "../BootScene";
import { Logo } from "./Logo";

export class SplashScene extends Phaser.Scene {

    public static currentInstance: SplashScene;

    private logo: Logo;

    constructor() {

        super("SplashScene");

        SplashScene.currentInstance = this;
    }

    public create(): void {

        GameManager.setCurrentScene(this);

        const bck = this.add.graphics();
        bck.fillStyle(GameConstants.BACKGROUND_COLOR);
        bck.fillRect(0, 0, GameVars.gameWidth, GameVars.gameHeight);

        const bck2 = this.add.image(0, GameVars.gameHeight, "bg");
        bck2.scaleY = GameVars.scaleY;
        bck2.setOrigin(0, 1);

        const tutorialBtn = new Button(this, GameVars.gameWidth - 55, GameVars.gameHeight - 55, "texture_atlas_1", "btn_tutorial", "btn_tutorial_hover");
        tutorialBtn.alpha = 0;
        tutorialBtn.scaleY = GameVars.scaleY;
        tutorialBtn.onDown(this.onClickTutorial, this);
        this.add.existing(tutorialBtn);

        const topContainer = new Phaser.GameObjects.Container(this, GameVars.gameWidth / 2, GameVars.desktop ? -80 : 0);
        topContainer.scaleY = GameVars.scaleY;
        this.add.existing(topContainer);

        this.logo = new Logo(this);
        topContainer.add(this.logo);

        const bottomContainer = new Phaser.GameObjects.Container(this, GameVars.gameWidth / 2, GameVars.desktop ? GameVars.gameHeight - 360 : GameVars.gameHeight - 450);
        bottomContainer.scaleY = GameVars.scaleY;
        this.add.existing(bottomContainer);

        const startFromLayer = new StartFromLayer(this);
        bottomContainer.add(startFromLayer);

        const playButton = new Button(this, 0, 280, "texture_atlas_1", "btn_play", "btn_play_hover");
        playButton.onUp(this.onClickPlay, this);
        bottomContainer.add(playButton);

        playButton.alpha = 0;

        this.tweens.add({
            targets: playButton,
            alpha: 1,
            y: 250,
            ease: Phaser.Math.Easing.Cubic.Out,
            duration: 500,
            delay: 2500
        });

        this.tweens.add({
            targets: tutorialBtn,
            alpha: 1,
            ease: Phaser.Math.Easing.Cubic.Out,
            duration: 500,
            delay: 2500
        });

        this.tweens.add({
            targets: playButton,
            scaleX: 1.065,
            scaleY: 1.065,
            ease: Phaser.Math.Easing.Cubic.Out,
            duration: 400,
            yoyo: true,
            repeat: -1
        });
    }

    public update(): void {

        this.logo.update();
    }

    public onClickPlay(): void {

        this.cameras.main.fadeOut(300, 255, 255, 255);

        this.cameras.main.once("camerafadeoutcomplete", function (): void {
            GameManager.enterBoardScene(false);
        });
    }

    public onClickTutorial(): void {

        GameManager.setStartFrom(1);

        this.cameras.main.fadeOut(300, 255, 255, 255);

        this.cameras.main.once("camerafadeoutcomplete", function (): void {
            GameManager.enterBoardScene(true);
        });
    }
}
